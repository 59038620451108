<template>
    <div class="d-flex content-center min-vh-100">
        <BContainer>
            <BRow class="justify-content-center">
                <BCol md="8">
                    <BCard class="text-left" footer-tag="footer" footer-bg-variant="light">
                        <div slot="header">
                            <strong><b-icon class="mr-1" icon="shield-lock-fill"/>회원정보 변경 확인</strong>
                            <div class="card-header-actions">
                                <!-- <small class="text-muted">XCOST.ME, XCost 회원정보 변경을 위한 메일을 발송합니다.</small> -->
                            </div>
                        </div>

                        <BForm @submit="onSubmit">
                            <BFormGroup class="mt-3"
                                        label="회원 아이디"
                                        label-for="userIdInput">
                                <BInputGroup id="userIdInput">
                                    <BInputGroupPrepend is-text><BIconPersonFill/></BInputGroupPrepend>
                                    <BFormInput v-model="userId"
                                                :state="userId.length>=2"
                                                type="text"
                                                id="userId"
                                                placeholder="회원 아이디를 입력하세요"
                                                required
                                    />
                                </BInputGroup>
                            </BFormGroup>

                            <BFormGroup class="mt-3"
                                        label="회원 가입시 입력한 이메일"
                                        label-for="emailInput"
                                        description="입력한 아이디와 이메일 주소가 일치하면 회원정보 수정 확인 메일을 전송합니다">
                                <BInputGroup id="emailInput">
                                    <BInputGroupPrepend is-text><BIconAt/></BInputGroupPrepend>
                                    <BFormInput v-model="userEmail"
                                                :state="validEmailState"
                                                type="email"
                                                placeholder="이메일 주소를 입력하세요"
                                                required
                                    />
                                </BInputGroup>
                            </BFormGroup>

                            <div class="text-right mt-3">
                                <BButton  variant="info" type="submit" :disabled="isSentUser || !validEmailState">이메일 확인</BButton>
                            </div>

                        </BForm>

<!--

                        <BRow >
                            <BCol>
                                <BFormInput class="mt-3" v-model="newPwd"  :state="validPwdState"  type="password" placeholder="비밀번호는 영문.숫자.특수문자 최소8자 이상입니다" :disabled="!isValidUser"/>
                                <BFormInput class="mt-2" v-model="newPwdConfirm" :state="validPwdConfirm" type="password" placeholder="비밀번호를 다시 입력해주새요" :disabled="!isValidUser"/>
                                <div class="text-right mt-3">
                                    <BButton  variant="danger" @click="setUserPwd" :disabled="!isValidUser || !isPwdChecked">비밀번호 변경</BButton>
                                </div>
                            </BCol>
                        </BRow>


-->
                        <div slot="footer">

                            <div class="small text-muted">
                                <strong><i class="fa fa-copyright"></i> <a href='http://qqkorea.net'>(주)하우코스트</a></strong>
                                <span class="hidden-xs en">All rights reserved.</span><br>
                                <span class="hidden-xs en">
                                    대표자 : 유현오 | E-Mail : <a href="mailto:estimate@howcost.co.kr">estimate@howcost.co.kr</a>) <br>
                                    <a href='http://xcost.me/bbs/qalist.php'>적산문의/프로그램/문의 1:1상담</a> | tel. 031-417-0466 <br/>
                                    사무실 : 경기도 수원시 권선구 수성로 8 경기중소기업성장지원센터 303호 <br/>부설연구소 : 건설계약연구소/벤쳐기업인증/연구개발서비스업등록/소프트웨어개발업/ISO9001,ISO14001인증<br/>
                                    사업자번호 : 809-86-00250</span>
                            </div>
                        </div>

                    </BCard>


                </BCol>
            </BRow>
        </BContainer>


    </div>
</template>

<script>
    import {
        apiCall,
        cloneVar,
        alertError,
        alertWarn,
        alertSuccess,
        alertConfirm,
        checkBisNo,
        commify,
        toastSync,
        warpSite
    } from '../../../common/utils';

    import DaumPostcode from 'vuejs-daum-postcode';
    import moment from "moment";

    export default {
        name: 'MemberFind',
        props: ['memberToken'],
        data () {
            return {
                userName : '',
                userEmail: '',
                userId  : '',
                isSentUser: false,
                newPwd: '',
                newPwdConfirm: '',
                failCount: 0,
                isValidUser: false,
                isPwdChecked: false,
                // pwdRegexp: /^(?=.*[0-9]+)[a-zA-Z][a-zA-Z0-9]{7,29}$/, // 영어+숫자(8자이상)
                pwdRegexp: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/, // 최소8자 영문,숫자,특수문자 조합
                emailRegexp: /^([0-9a-zA-Z_\.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/
            }
        },
        async created(){

            console.log("-------- created: MemberFind -----" );
            try{

            }catch(err){
                console.log(err);
                await alertWarn( this.$bvModal, 'ERROR: '+err.message, '에러' );
                return;
            }
        },
        beforeMount() {

        },
        mounted(){
            console.log("-------- mounted: MemberFind -----  " );

        },

        computed: {

            validPwdState(){
                let ret = false;
                // const p2 = this.newPwdConfirm;
                if( this.newPwd.length > 7 ){
                    if( this.pwdRegexp.test( this.newPwd )) ret = true;
                }
                return ret;
            },

            validEmailState(){
                if(!this.emailRegexp.test(this.userEmail)) {
                    return false;
                } else {
                    return true;
                }
            },

            validPwdConfirm(){
                let ret = false;
                const p1 = this.newPwd;
                const p2 = this.newPwdConfirm;
                if( p2.length > 7 && p1 === p2 ){
                    ret = true;
                    this.isPwdChecked = true;
                }else{
                    this.isPwdChecked = false;
                }
                return ret;
            },
        },
        methods: {

            async onSubmit(e){
                e.preventDefault();

                console.log( "----------- IdPwdLost ---> ");
                try{
                    const param = {
                        email : this.userEmail,
                        id : this.userId,
                        hostName: window.location.hostname
                    };

                    const verify = await apiCall('post', `/api/member/verify-id-email`, param);
                    console.log(" verify ========> ", verify);
                    if(verify.result === false) {
                        await alertWarn(this.$bvModal, '존재하지 않는 계정이거나 이메일이 일치하지 않습니다');
                        return;
                    }

                    const r = await apiCall('post', `/api/member/update-request-email`, param);
                    console.log( r );
                    if(r.code===200) {
                        await alertSuccess(this.$bvModal, "변경 요청 메일을 전송하였습니다. 2시간 이내 변경하시기 바랍니다.", "SUCCESS");
                        // window.location.href = 'http://xcost.me';
                        window.location.href = warpSite();
                        return;
                    } else {
                        this.failCount++;
                        await alertWarn( this.$bvModal, '이메일 확인 실패: '+ r.message, '정보 없음' );
                        this.userName = '';
                        this.userEmail = '';

                        if( this.failCount >= 10 ) {
                            alert('10회 이상 실패하였습니다.\n이메일 확인이 어려운 경우 관리자에게 문의하시기 바랍니다');
                            // window.location.href = 'http://xcost.me';
                            window.location.href = warpSite();
                        }
                    }

                }catch(err){
                    console.log( err );
                    await alertWarn( this.$bvModal, 'ERROR: ' + err.message, '에러' );
                }
            },


        }

    }
</script>
